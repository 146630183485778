// src/pages/contact.js
import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const NotfoundPage = ({ data }) => {
  return <Layout>404 Not Found</Layout>;
};

export default NotfoundPage;

export const Head = () => (
  <Seo
    title="404 Not Found"
    description="お探しのページが見つかりませんでした。"
  />
);
